<!-- 我的反馈 -->
<template>
  <div>
    <div class="feedBack">
      <div class="title">
        <div style="display: flex;flex: 1;">
        <el-badge style="margin-right: 2%;" :value="notreads[tab.label]||notreads[tab.name]"
                  :hidden="!notreads[tab.label]&&!notreads[tab.name]"
                  v-for="(tab, index) in currentTabList"
                  :key="index"
                  :v-if="(!['meMessageCourse'].includes(tab.name) || (['meMessageCourse'].includes(tab.name) && userType === 2))"
        >
          <div :class="{system:true, item:true, magBgc: ($route.matched[routerViewDepth+1]||currentRoute).name===tab.name}"
               @click="$handleRoute(tab.name)"
          > {{ tab.label }}</div>
        </el-badge>
      </div>

       
      </div>
      <div class="form">
        <router-view @updateData="updateData" v-bind:pageStyle="'display: flex; justify-content: center; text-align: center; margin-top: 20px;'"></router-view>
      </div>
      
    </div>
    <!-- 反馈弹框 -->
  </div>
</template>

<script>
import {messageCount } from "@/api/me.js"

export default {
  props: {
    userData:{},
  },
  data () {
    //这里存放数据
    return {
      tabActiveName:'',
      taList:[],
      currentTabList:[],
      loading: false,
	    notreads:{"msg1_notread":0, "msg2_notread":0, "apply_notread":0,"course_notread":0}
    }
  },
  //方法集合
  methods: {
    updateData(){
      console.log("刷新页面");
      this.messageCount();
    },
    routeUpdate(){
      this.taList = this.$router.getRoutes().filter(d=>(d.parent || {}).name === this.currentRouteName && (d.meta || {}).hidden !== true).map(d=>{
        d.label = (d.meta || {}).title || d.name;
        return d;
      });
      this.messageCount();
    },
    routeChange({routerViewDepth}){
      if(this.userInfo.type === 2){
        this.currentTabList = [...this.taList]
      }else{
        this.currentTabList = this.taList.filter(d=>['meMessageCourse'].indexOf(d.name) === -1)
      }
      let $route = this.$route.matched[routerViewDepth + 1] || this.$route;
      let activeName = (this.currentTabList.filter(d=>$route.name.indexOf(d.name) === 0)[0] || {}).name
      if(!activeName){
        this.$handleRoute(this.currentTabList[0].name, true)
      }else{
        this.tabActiveName = activeName;
      }
    },
    messageCount(){
      messageCount({}).then(({ data }) => {
            this.notreads = Object.assign({},this.notreads,data);
            console.log(this.notreads,"this.notreads");
            this.$store.commit("SET" , {key:"msg_notread" , value:this.notreads.notread})

            this.$emit('update:userData',{...this.userData,msg_notread:data.notread})
      })
    },
    
  },
};
</script>
<style  lang='less' scoped>
.feedBack {
position: relative;
  .feedback-form {
    ::v-deep {
      .el-table tr {
        cursor: pointer;
      }
    }
  }
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 20px 30px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .system {
      padding:0 20px;
      min-width: 86px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      box-sizing: border-box;

      color: #303030;
      background: #ffffff;
      border: 1px solid #979797;
      cursor: pointer;
    }
    .magBgc {
      background: #00957e;
      color: #ffffff;
      border: 0;
    }
  }
  .form {
    margin: 20px 0;
  }
}
</style>